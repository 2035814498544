import React from "react";
import PropTypes from "prop-types";
import BulmaImage from "../Bulma/Image";

const LinkTeratail = ({ accountId }) => (
  <a
    href={`https://teratail.com/users/${accountId}`}
    className="social-icon-a social-icon-size"
  >
    <BulmaImage src="/img/teratail.png" className="social-icon-size" />
  </a>
);

LinkTeratail.propTypes = {
  accountId: PropTypes.string,
};

export default LinkTeratail;
