import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const Star = ({ level }) => (
  <div className="columns is-gapless is-mobile stars">
    {[...Array(parseInt(level))].map((e, i) => (
      <span key={i} className="icon has-text-primary column is-one-fifth">
        <FaStar />
      </span>
    ))}
    {level % 1 ? (
      <span className="icon has-text-primary column is-one-fifth">
        <FaStarHalfAlt />
      </span>
    ) : null}
  </div>
);

export default Star;
