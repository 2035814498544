import React from "react";
import Helmet from "react-helmet";
import Hero from "../components/Hero";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import AboutPageTemplate from "../components/AboutPageTemplate";
import Layout from "../components/Layout";
import SectionGradient from "../components/SectionGradient";

const AboutPage = ({ data }) => {
  const members = data.allAboutYaml.edges;
  const { frontmatter } = data.markdownRemark;
  const { childImageSharp } = data.file;

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.meta_title}</title>
        <meta name="description" content={frontmatter.meta_description} />
      </Helmet>
      <Hero
        title={frontmatter.title}
        background_image={childImageSharp.fluid}
      />
      <SectionGradient>
        <AboutPageTemplate members={members} standard={frontmatter.standard} />
      </SectionGradient>
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    allAboutYaml {
      edges {
        node {
          id
          name_alphabet
          name_kanji
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          socials {
            site_name
            account_id
          }
          domain
          experience
          teamwork
          tech_stack {
            category
            star
            stacks {
              name
              star
            }
          }
          other {
            content
            title
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
        standard {
          definition {
            star
            explaination
          }
          explaination
          origin {
            level
            title
            explanation
          }
        }
      }
    }
    file(relativePath: { eq: "marina-loucaides-VmziYgps3T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
