import React from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";

const LinkFont = ({ children, href, color = undefined }) => (
  <IconContext.Provider
    value={{ color: color, className: "has-text-primary social-icon-size" }}
  >
    <a href={href} className="social-icon-a icon social-icon-size">
      {children}
    </a>
  </IconContext.Provider>
);

LinkFont.propTypes = {
  children: PropTypes.element.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
};

export default LinkFont;
