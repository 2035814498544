import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Star from "./star";
import BulmaModalFx from "../Bulma/Modal/Fx";

const SkillMap = ({ member, standard }) => (
  <div className="columns is-centered">
    <div className="column is-narrow">
      <h3 className="has-text-weight-semibold is-size-3">スキルマップ</h3>
      <p>
        <div className="overflowable mb-5">
          <table className="table">
            <thead>
              <tr>
                <th>分野</th>
                <th>習熟度</th>
                <th>詳細</th>
              </tr>
            </thead>
            {member.node.tech_stack.map(({ category, star, stacks }) => (
              <tr key={category}>
                <td>{category}</td>
                <td>
                  <Star level={star} />
                </td>
                <td>
                  <BulmaModalFx
                    buttonTitle="Expand"
                    modalTitle={`${category} 詳細`}
                  >
                    <table className="table is-fullwidth">
                      <thead>
                        <tr>
                          <th>技術</th>
                          <th>習熟度</th>
                        </tr>
                      </thead>
                      {stacks.map(({ name, star }) => (
                        <tr key={name}>
                          <td>{name}</td>
                          <td>
                            <Star level={star} />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </BulmaModalFx>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <BulmaModalFx buttonTitle="基準について" modalTitle="基準について">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>習熟度</th>
                <th>基準</th>
              </tr>
            </thead>
            {standard.definition.map(({ star, explaination }) => (
              <tr key={star}>
                <td>
                  <Star level={star} />
                </td>
                <td>{explaination}</td>
              </tr>
            ))}
          </table>
          <ReactMarkdown source={standard.explaination} />
        </BulmaModalFx>
      </p>
    </div>
  </div>
);

SkillMap.propTypes = {
  member: PropTypes.object,
  standard: PropTypes.object,
};

export default SkillMap;
