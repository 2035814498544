import React from "react";
import PropTypes from "prop-types";
import {
  FaDocker,
  FaStackOverflow,
  FaGithub,
  FaPython,
  FaTwitter,
  FaWordpress,
} from "react-icons/fa";
import LinkFont from "./linkFont";
import LinkTeratail from "./linkTeratail";

const Socials = ({ socials }) => (
  <div className="columns is-marginless">
    <nav className="column is-paddingless">
      <ul className="columns is-marginless is-multiline is-mobile">
        {socials.map(({ site_name, account_id }) => (
          <li key={site_name} className="column is-narrow social">
            {(() => {
              switch (site_name) {
                case "GitHub":
                  return (
                    <LinkFont
                      href={`https://github.com/${account_id}`} /* color="#000000" */
                    >
                      <FaGithub />
                    </LinkFont>
                  );
                case "Docker Hub":
                  return (
                    <LinkFont
                      href={`https://hub.docker.com/u/${account_id}`} /* color="#2496ed" */
                    >
                      <FaDocker />
                    </LinkFont>
                  );
                case "PyPI":
                  return (
                    <LinkFont
                      href={`https://pypi.org/user/${account_id}`} /* color="#0073b7" */
                    >
                      <FaPython />
                    </LinkFont>
                  );
                case "WordPress.org":
                  return (
                    <LinkFont
                      href={`https://profiles.wordpress.org/${account_id}`} /* color="#676766" */
                    >
                      <FaWordpress />
                    </LinkFont>
                  );
                case "teratail":
                  return <LinkTeratail accountId={account_id} />;
                case "Stack Overflow":
                  return (
                    <LinkFont
                      href={`https://stackoverflow.com/users/${account_id}`} /* color="#f48024" */
                    >
                      <FaStackOverflow />
                    </LinkFont>
                  );
                case "Twitter":
                  return (
                    <LinkFont
                      href={`https://twitter.com/${account_id}`} /* color="#08a0e9"><FaTwitter */
                    >
                      <FaTwitter />
                    </LinkFont>
                  );
                default:
                  throw new Error("site_name is not supported now.");
              }
            })()}
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

Socials.propTypes = {
  socials: PropTypes.object,
};

export default Socials;
