import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Socials from "./socials";

const ProfileMedia = ({ member }) => (
  <article className="media mb-3 member-profile">
    <Image
      Tag="figure"
      className="image is-128x128 media-left"
      imgStyle={{ borderRadius: "50%" }}
      placeholderClassName="is-rounded"
      fluid={member.node.image.childImageSharp.fluid}
      loading="eager"
      durationFadeIn={100}
    />
    <div className="media-content">
      <h2 className="title is-size-4">{member.node.name_kanji}</h2>
      <div className="subtitle">
        <strong>{member.node.name_alphabet}</strong>
      </div>
      <Socials socials={member.node.socials} />
    </div>
  </article>
);

ProfileMedia.propTypes = {
  member: PropTypes.object,
};

export default ProfileMedia;
