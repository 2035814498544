import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ProfileMedia from "./profileMedia";
import SkillMap from "./skillMap";

const AboutPageTemplate = ({ members, standard }) => (
  <>
    {members.map((member) => (
      <div key={member.node.name_alphabet} className="tile is-ancestor">
        <div className="tile is-vertical">
          <div className="tile">
            <div className="tile is-vertical is-parent is-5">
              <div className="tile is-child box">
                <ProfileMedia member={member} />
              </div>
              <div className="tile is-child box">
                <SkillMap member={member} standard={standard} />
              </div>
            </div>
            <div className="tile is-parent is-vertical">
              <div className="tile is-child box">
                <h3 className="has-text-weight-semibold is-size-3">
                  技術分野について
                </h3>
                <p className="content">
                  <ReactMarkdown source={member.node.domain} />
                </p>
              </div>
              <div className="tile is-child box">
                <h3 className="has-text-weight-semibold is-size-3">
                  業務経験について
                </h3>
                <p className="content">
                  <ReactMarkdown source={member.node.experience} />
                </p>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child box">
              <h3 className="has-text-weight-semibold is-size-3">
                チームでのはたらき方
              </h3>
              <p className="content">
                <ReactMarkdown source={member.node.teamwork} />
              </p>
            </div>
          </div>
          {member.node.other.map(({ title, content }) => (
            <div key={title} className="tile is-parent">
              <div className="tile is-child box">
                <h3 className="has-text-weight-semibold is-size-3">{title}</h3>
                <p className="content">
                  <ReactMarkdown source={content} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </>
);

AboutPageTemplate.propTypes = {
  members: PropTypes.object,
  standard: PropTypes.object,
};

export default AboutPageTemplate;
